














import { Component, Inject, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import { ExternalFileApi } from '@/api/external_file_api';
import CaseStudy from '@/api/models/case_study';
import CaseStudyInputTemplate from '@/components/CaseStudyInputTemplate.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMe } from '@/store/global_me';
import { GlobalMessageModule } from '@/store/global_message';
import { GlobalTag } from '@/store/global_tag';
import { makeForm } from '@/utils/form';
import { KbnConst } from '@/utils/kbn';

@Component({
  components: {
    CaseStudyInputTemplate
  }
})
export default class CaseStudyNew extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  externalFileApi!: ExternalFileApi;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  globalMessageModule!: GlobalMessageModule;

  @Inject()
  globalTagModule!: GlobalTag;

  @Inject()
  globalMeModule!: GlobalMe;

  active = false;

  form = makeForm(CaseStudy, new CaseStudy({
    publishKbn: KbnConst.PUBLISH_KBN_PUBLIC,
  }));

  get tags() {
    return this.globalTagModule.tags;
  }

  async onSave() {
    if (this.active) return;
    this.active = true;
    try {
      const caseStudyUuid = await this.globalLoadingScreenModule.track(
        this.api.postCaseStudy(this.form)
      );
      this.globalMessageModule.addMessages('info', [`${this.form.title}の事例を作成しました。`], 'next');
      this.$router.push({ name: 'CaseStudyShow', params: { 'case_study_uuid': caseStudyUuid } });
    } finally {
      this.active = false;
    }
  }

  onCancel() {
    this.$router.push({ name: 'CaseStudiesIndex' });
  }  
}
